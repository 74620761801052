export const DEFAULT_CONTACT_LIST_ITEMS = {
  current_page: 1,
  data: [],
  first_page_url: null,
  from: 1,
  new_leads_count: 0,
  next_page_url: null,
  path: null,
  per_page: 20,
  prev_page_url: null,
  to: 20,
  total_contact_count: 0,
  unreads_count: 0
}
