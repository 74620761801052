export const COUNT_FIELDS = [
  'unread_texts_count',
  'unread_missed_calls_count',
  'unread_voicemails_count',
  'inbound_calls_count',
  'inbound_texts_count',
  'inbound_communications_count',
  'outbound_calls_count',
  'outbound_texts_count',
  'outbound_communications_count',
  'communications_count'
]
