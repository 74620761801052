export const DEFAULT_STATE = {
  calls: {
    live: [],
    parked: [],
    queued: []
  },
  defaultLiveCallsColumns: [
    'details',
    'disposition',
    'incoming_number',
    'ring_group',
    'start',
    'talk_time',
    'lead_number',
    'lead_location',
    'user',
    'tags',
    'operations'
  ],
  defaultParkedCallsColumns: [
    'details',
    'disposition',
    'incoming_number',
    'ring_group',
    'start',
    'talk_time',
    'lead_number',
    'lead_location',
    'user',
    'tags',
    'operations'
  ],
  defaultQueuedCallsColumns: [
    'details',
    'disposition',
    'incoming_number',
    'ring_group',
    'start',
    'talk_time',
    'lead_number',
    'lead_location',
    'attempting_users',
    'tags',
    'notes',
    'operations'
  ],
  callsEnabledColumns: [],
  filters: {
    ringGroup: null,
    agent: null,
    agentStatus: 'all'
  },
  isLiveCallsLoading: false,
  isOverviewDetailed: false,
  isParkedCallsLoading: false,
  isQueuedCallsLoading: false,
  isSummaryLoading: false,
  summary: {
    abandonedCalls: 0,
    answeredCalls: 0,
    appointmentsSet: 0,
    averageTalkTime: 0,
    averageWaitTime: 0,
    emailsReceived: 0,
    emailsSent: 0,
    faxesReceived: 0,
    faxesSent: 0,
    missedCalls: 0,
    remindersSet: 0,
    smsReceived: 0,
    smsSent: 0,
    textsReceived: 0,
    textsSent: 0,
    totalCalls: 0,
    totalOccupancy: 0
  },
  viewMode: 'comfort' // comfort, compact
}
