export const HOUR_VIEW = 'hour'
export const DAY_VIEW = 'day'
export const WEEK_VIEW = 'week'
export const MONTH_VIEW = 'month'

export const DEFAULT_VIEW = MONTH_VIEW

export const CALENDAR_VIEWS = [HOUR_VIEW, DAY_VIEW, WEEK_VIEW, MONTH_VIEW]

export const TIME_FORMAT_AM_PM = 1
export const TIME_FORMAT_24_H = 2
