// source: https://www.twilio.com/docs/messaging/services/smart-encoding-char-list
export const DEFAULT_SEGMENT_MAX_CHAR = 160
export const DEFAULT_SEGMENTS = 0
export const UNICODE_SMS_PAGES = [70, 64, 67]
export const ASCII_SMS_PAGES = [160, 146, 153]

export const SMART_ENCODING_CHARS = [
  '00ab',
  '00bb',
  '201c',
  '201d',
  '02ba',
  '02ee',
  '201f',
  '275d',
  '275e',
  '301d',
  '301e',
  'ff02',
  '2018',
  '2019',
  '02bb',
  '02c8',
  '02bc',
  '02bd',
  '02b9',
  '201b',
  'ff07',
  '00b4',
  '0301',
  '02ca',
  '0060',
  '02cb',
  '275b',
  '275c',
  '0313',
  '0314',
  'fe10',
  'fe11',
  '00f7',
  '00bc',
  '00bd',
  '00be',
  '29f8',
  '0337',
  '0338',
  '2044',
  '2215',
  'ff0f',
  '29f9',
  '29f5',
  '20e5',
  'fe68',
  'ff3c',
  '0332',
  'ff3f',
  '20d2',
  '20d3',
  '2223',
  'ff5c',
  '23b8',
  '23b9',
  '23d0',
  '239c',
  '239f',
  '23bc',
  '23bd',
  '2015',
  'fe63',
  'ff0d',
  '2010',
  '2022',
  '2043',
  'fe6b',
  'ff20',
  'fe69',
  'ff04',
  '01c3',
  'fe15',
  'fe57',
  'ff01',
  'fe5f',
  'ff03',
  'fe6a',
  'ff05',
  'fe60',
  'ff06',
  '201a',
  '0326',
  'fe50',
  '3001',
  'fe51',
  'ff0c',
  'ff64',
  '2768',
  '276a',
  'fe59',
  'ff08',
  '27ee',
  '2985',
  '2769',
  '276b',
  'fe5a',
  'ff09',
  '27ef',
  '2986',
  '204e',
  '2217',
  '229b',
  '2722',
  '2723',
  '2724',
  '2725',
  '2731',
  '2732',
  '2733',
  '273a',
  '273b',
  '273c',
  '273d',
  '2743',
  '2749',
  '274a',
  '274b',
  '29c6',
  'fe61',
  'ff0a',
  '02d6',
  'fe62',
  'ff0b',
  '3002',
  'fe52',
  'ff0e',
  'ff61',
  'ff10',
  'ff11',
  'ff12',
  'ff13',
  'ff14',
  'ff15',
  'ff16',
  'ff17',
  'ff18',
  'ff19',
  '02d0',
  '02f8',
  '2982',
  'a789',
  'fe13',
  'ff1a',
  '204f',
  'fe14',
  'fe54',
  'ff1b',
  'fe64',
  'ff1c',
  '0347',
  'a78a',
  'fe66',
  'ff1d',
  'fe65',
  'ff1e',
  'fe16',
  'fe56',
  'ff1f',
  'ff21',
  '1d00',
  'ff22',
  '0299',
  'ff23',
  '1d04',
  'ff24',
  '1d05',
  'ff25',
  '1d07',
  'ff26',
  'a730',
  'ff27',
  '0262',
  'ff28',
  '029c',
  'ff29',
  '026a',
  'ff2a',
  '1d0a',
  'ff2b',
  '1d0b',
  'ff2c',
  '029f',
  'ff2d',
  '1d0d',
  'ff2e',
  '0274',
  'ff2f',
  '1d0f',
  'ff30',
  '1d18',
  'ff31',
  'ff32',
  '0280',
  'ff33',
  'a731',
  'ff34',
  '1d1b',
  'ff35',
  '1d1c',
  'ff36',
  '1d20',
  'ff37',
  '1d21',
  'ff38',
  'ff39',
  '028f',
  'ff3a',
  '1d22',
  '02c6',
  '0302',
  'ff3e',
  '1dcd',
  '2774',
  'fe5b',
  'ff5b',
  '2775',
  'fe5c',
  'ff5d',
  'ff3b',
  'ff3d',
  '02dc',
  '02f7',
  '0303',
  '0330',
  '0334',
  '223c',
  'ff5e',
  '00a0',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '200a',
  '200b',
  '202f',
  '205f',
  '3000',
  'feff',
  '008d',
  '009f',
  '0080',
  '0090',
  '009b',
  '0010',
  '0009',
  '0000',
  '0003',
  '0004',
  '0017',
  '0019',
  '0011',
  '0012',
  '0013',
  '0014',
  '2017',
  '0333',
  '2014',
  '2013',
  '2039',
  '203a',
  '203c',
  '201e',
  '2026',
  '2028',
  '2029',
  '2060',
  '0009', // Tab
  '0000',
  '0003',
  '0004',
  '0017',
  '0019',
  '0011',
  '0012',
  '0013',
  '0014',
  '2017',
  '2014',
  '2013',
  '2039',
  '203a',
  '203c',
  '201e',
  '2026',
  '2028',
  '2029',
  '2060'
]

// These are one ucs-2 characters which replace by multiple ascii characters
// For example => ½ will be replaced by 1/2 which is 3 characters and not 1
export const SMART_ENCODING_CHAR_COUNT = [
  { key: '203c', value: 2 },
  { key: '2026', value: 3 },
  { key: '00bc', value: 3 },
  { key: '00bd', value: 3 },
  { key: '00be', value: 3 }
]
