<template>
  <svg class="svg" :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="cancel-call-icon-svg">
    <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="#FA003F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3611 13.003C14.4226 13.3305 14.5349 13.4971 14.9279 13.6427C15.5117 13.8591 17.118 14.166 17.6871 13.8904C17.9499 13.7631 18.0978 13.5187 18.1921 13.1469C18.4701 12.0522 18.1132 11.1941 17.3083 10.4533C14.5824 7.94359 9.41403 7.94658 6.69013 10.4543C5.88562 11.1948 5.5293 12.0525 5.8077 13.1475C5.9022 13.5193 6.0503 13.7638 6.31328 13.8911C6.88233 14.1671 8.48853 13.8611 9.07216 13.645C9.46527 13.4995 9.57735 13.3331 9.63866 13.0056C9.84491 11.8994 9.26872 11.3285 10.5671 11.0455C11.5409 10.8332 12.458 10.8296 13.4316 11.0423C14.7302 11.3261 14.1542 11.8968 14.3611 13.003Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'cancel-call-icon',
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
