export const MAX_SCREEN_WIDTH_MOBILE_HEADER = 784
export const MIN_TABLET_WIDTH = 605
export const MAX_TABLET_WIDTH = 1084
export const PD_MAX_FILTER_LG = 1350
export const MOBILE_LARGE_WIDTH = 486
export const MOBILE_HEADER_TRANSITION_WIDTH = 450
export const EXTRA_SMALL_MOBILE_WIDTH = 430

export const VIEWPORT_BREAKPOINTS = {
  MOBILE_MAX_WIDTH: 767,
  TABLET_MIN_WIDTH: 768,
  TABLET_MAX_WIDTH: 1023,
  SMALL_DESKTOP_MIN_WIDTH: 1024,
  SMALL_DESKTOP_MAX_WIDTH: 1279,
  MEDIUM_DESKTOP_MIN_WIDTH: 1280,
  MEDIUM_DESKTOP_MAX_WIDTH: 1439,
  LARGE_DESKTOP_MIN_WIDTH: 1440,
  LARGE_DESKTOP_MAX_WIDTH: 1919,
  EXTRA_LARGE_DESKTOP_MIN_WIDTH: 1920
}

export const VIEWPORTS = [
  {
    name: 'mobile',
    minWidth: 0,
    maxWidth: VIEWPORT_BREAKPOINTS.MOBILE_MAX_WIDTH
  },
  {
    name: 'tablet',
    minWidth: VIEWPORT_BREAKPOINTS.TABLET_MIN_WIDTH,
    maxWidth: VIEWPORT_BREAKPOINTS.TABLET_MAX_WIDTH
  },
  {
    name: 'smallDesktop',
    minWidth: VIEWPORT_BREAKPOINTS.SMALL_DESKTOP_MIN_WIDTH,
    maxWidth: VIEWPORT_BREAKPOINTS.SMALL_DESKTOP_MAX_WIDTH
  },
  {
    name: 'mediumDesktop',
    minWidth: VIEWPORT_BREAKPOINTS.MEDIUM_DESKTOP_MIN_WIDTH,
    maxWidth: VIEWPORT_BREAKPOINTS.MEDIUM_DESKTOP_MAX_WIDTH
  },
  {
    name: 'largeDesktop',
    minWidth: VIEWPORT_BREAKPOINTS.LARGE_DESKTOP_MIN_WIDTH,
    maxWidth: VIEWPORT_BREAKPOINTS.LARGE_DESKTOP_MAX_WIDTH
  },
  {
    name: 'extraLargeDesktop',
    minWidth: VIEWPORT_BREAKPOINTS.EXTRA_LARGE_DESKTOP_MIN_WIDTH,
    maxWidth: Infinity
  }
]
