<template>
  <svg class="svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3125 1.65625C11.3125 1.29381 11.0187 1 10.6562 1C10.2938 1 10 1.29381 10 1.65625L9.99937 2.365H5.8125V1.65625C5.8125 1.29381 5.51868 1 5.15625 1C4.79382 1 4.5 1.29381 4.5 1.65625V2.365H3.625C2.17525 2.365 1 3.54025 1 4.99V5.86499V6.25V12.375C1 13.8247 2.17525 15 3.625 15H12.375C13.8247 15 15 13.8247 15 12.375V6.25V5.86499V4.99C15 3.54025 13.8247 2.365 12.375 2.365H11.3119L11.3125 1.65625ZM3.1875 6.30249H12.8125C13.2957 6.30249 13.6875 6.69424 13.6875 7.17749V12.375C13.6875 13.0999 13.0999 13.6875 12.375 13.6875H3.625C2.90013 13.6875 2.3125 13.0999 2.3125 12.375V7.17749C2.3125 6.69424 2.70425 6.30249 3.1875 6.30249Z" fill="#00BF4A"/>
  </svg>
</template>

<script>
export default {
  name: 'appointment-icon',
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 16
    }
  }
}
</script>
