<template>
  <svg class="svg" :width="width" :height="height" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.46432 6.52945C2.90956 6.26706 3.28565 6.17788 3.66151 6.31154C4.47543 6.60088 5.74065 8.45017 6.10057 9.22206C6.34306 9.74167 6.29344 10.009 6.04129 10.3803C5.1892 11.6336 4.08767 11.6184 5.07649 13.1473C5.81788 14.2937 6.71039 15.1792 7.85638 15.9212C9.38452 16.9106 9.36926 15.8087 10.6219 14.9559C10.993 14.7037 11.26 14.654 11.7796 14.8966C12.5511 15.2567 14.3994 16.5226 14.6883 17.337C14.8222 17.713 14.733 18.0893 14.4708 18.5348C13.6986 19.8467 12.5369 20.3176 11.0523 20.2423C6.02512 19.9882 1.01185 14.9806 0.75768 9.94969C0.682463 8.46433 1.15308 7.30178 2.46432 6.52945ZM9.62073 5.75L9.71831 5.75327C12.722 6.09717 14.8542 8.28847 15.245 11.2714C15.288 11.6 15.0566 11.9012 14.728 11.9443C14.3994 11.9873 14.0982 11.7559 14.0551 11.4273C13.7345 8.98026 12.0271 7.22545 9.58182 6.94548C9.2526 6.90779 9.01627 6.61035 9.05396 6.28113C9.08788 5.98483 9.3322 5.76377 9.62073 5.75ZM9.62854 8.60528L9.72607 8.60982C11.1753 8.79489 12.2028 9.83387 12.3843 11.2744C12.4258 11.6031 12.1928 11.9032 11.864 11.9447C11.5353 11.9861 11.2352 11.7532 11.1937 11.4244C11.0804 10.5246 10.478 9.91559 9.57406 9.80015C9.24536 9.75818 9.01292 9.45768 9.0549 9.12898C9.09268 8.83315 9.33986 8.61529 9.62854 8.60528Z" fill="#4450C0"/>
    <path d="M19.5 19.0028V16.489C19.5 13.5649 20.6643 10.7611 22.7356 8.69707L23.4846 7.95068" stroke="#4450C0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.834 7L24.5 7V11.6" stroke="#4450C0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: 'outbound-call-inprogress-icon',
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
