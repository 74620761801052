<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#FA003F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7359 18.9154C17.4797 19.2926 17.4293 19.5642 17.6756 20.0921C18.0413 20.8762 19.3266 22.7549 20.1534 23.0488C20.5352 23.1846 20.9173 23.094 21.3696 22.8274C22.7016 22.0428 23.1797 20.8619 23.1033 19.3529C22.8451 14.2421 17.7522 9.15502 12.6453 8.89691C11.1371 8.82046 9.95695 9.2988 9.17254 10.6316C8.90613 11.0841 8.81562 11.4663 8.95156 11.8484C9.24512 12.6756 11.1228 13.9616 11.9065 14.3275C12.4343 14.5739 12.7055 14.5235 13.0825 14.2672C14.3551 13.4009 14.3395 12.2815 15.8919 13.2866C17.0561 14.0403 17.9628 14.94 18.7159 16.1045C19.7205 17.6578 18.6015 17.6422 17.7359 18.9154Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'call-icon',
  props: {
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 32
    }
  }
}
</script>
