import _ from 'lodash'
import * as KycLogs from '../../constants/kyc-logs'
import { mapState } from 'vuex'

export default _.merge({
  computed: {
    ...mapState('auth', ['profile']),
    ...mapState('cache', ['currentCompany']),
    ...mapState('contacts', ['selectedLine']),

    viewOnly () {
      return this.isViewOnlyAccess()
    },

    currentKycStatus () {
      return this.getStatus()
    },

    ssuEnabled () {
      return this.statics?.kyc_ssu_enabled || false
    },

    isTrialKYC () {
      if (!this.ssuEnabled) {
        return false
      }

      return this.currentCompany?.is_kyc && this.currentCompany?.is_trial
    },

    isCompanyKYC () {
      if (!this.ssuEnabled) {
        return false
      }

      return this.currentCompany?.is_kyc
    },

    isCompanyA2pCampaignApproved () {
      return this.currentCompany?.is_a2p_campaign_approved
    },

    isKYCFilled () {
      if (!this.ssuEnabled) {
        return false
      }

      return this.currentCompany?.kyc_filled
    }
  },

  methods: {
    skipRestrictions (kycStatus) {
      return !this.ssuEnabled || kycStatus === KycLogs.KYC_STATUS_NONE
    },

    getStatus () {
      // Gets the KYC status from the company
      return this.currentCompany?.is_trial ? this.currentCompany.kyc_status : KycLogs.KYC_STATUS_NONE
    },

    shouldAllowSmsTraffic (selectedLine) {
      if (!selectedLine) {
        return true
      }

      /**
       * Allow sms traffic on Dialer and Contact text Composer component
       */
      if (!selectedLine.is_10_dlc) {
        /**
         *  1 - No A2P Campaign + No 10DLC Line -> Allow messaging
         *  2 - A2P Campaign  + No 10DLC (TF) ->  Allow messaging
         */
        return true
      }

      if (this.isCanadaLine(selectedLine)) {
        /**
         * 3 - 10DLC Canada lines -> Allow messaging
         */
        return true
      }

      /**
       *   3 - A2P Campaign + 10DLC Line -> Allow messaging
       *   4 - No A2P Campaign + 10DLC -> Not allowed
       */
      return selectedLine.is_10_dlc && selectedLine.has_approved_a2p_use_case
    },

    enabledToCreateContacts () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      return KycLogs.CREATE_CONTACTS_ALLOWED.includes(kycStatus)
    },

    enabledToImportContacts () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      return KycLogs.IMPORT_CONTACTS_ALLOWED.includes(kycStatus)
    },

    enabledToCallNumber (phone) {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      phone = this.$options.filters.fixPhone(phone)

      if (phone === this.profile?.phone_number) {
        return KycLogs.ONESELF_CALLS_ALLOWED.includes(kycStatus)
      }

      return KycLogs.CALLS_TO_OTHERS_ALLOWED.includes(kycStatus)
    },

    enabledToTextNumber () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      return KycLogs.TEXTS_ALLOWED.includes(kycStatus)
    },

    singleTestNumberPurchased () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      return KycLogs.SINGLE_TEST_NUMBER_PURCHASED_ALLOWED.includes(kycStatus)
    },

    enabledToBuyNewNumbers () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      return KycLogs.BUY_NEW_NUMBERS_ALLOWED.includes(kycStatus)
    },

    enabledToAddSequences () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      return KycLogs.ADD_SEQUENCES_ALLOWED.includes(kycStatus)
    },

    enabledToAddBroadcasts () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      return KycLogs.ADD_BROADCASTS_ALLOWED.includes(kycStatus)
    },

    allowedToEnableIntegrationsPage () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      return KycLogs.ENABLE_INTEGRATIONS_ALLOWED.includes(kycStatus)
    },

    enabledToSkipTrialAndSubscribe () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return true
      }

      return KycLogs.SKIP_TRIAL_ALLOWED.includes(kycStatus)
    },

    isViewOnlyAccess () {
      const kycStatus = this.getStatus()

      if (this.skipRestrictions(kycStatus)) {
        return false
      }

      return KycLogs.VIEW_ONLY_ALLOWED.includes(kycStatus)
    },

    onOpenFinishRegistration () {
      const link = `${process.env.API_URL}/account?tab=compliance&open_register_business_information=true`
      return window.open(link, '_self')
    },

    isCanadaLine (selectedLine) {
      return selectedLine?.incoming_numbers?.filter(number => number.country === 'CA').length > 0
    }
  }
})
