<template>
  <svg class="svg" :width="width" :height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(0.064 0 0 0.0701 21.1111 28.9903)" id="577862">
      <g style="">
        <g transform="matrix(-21.668 -27.4417 27.4417 -21.668 -132.4809 0.0021)" id="985131">
          <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(156,39,187); fill-rule: evenodd; opacity: 1;" transform=" translate(-8.0021, -13.002)" d="M 11.0082 15.1471 C 11.0865 15.5565 11.2294 15.7646 11.7298 15.9465 C 12.473 16.217 14.5179 16.6005 15.2423 16.2561 C 15.5769 16.0971 15.7653 15.7916 15.8853 15.3269 C 16.2392 13.9589 15.7849 12.8865 14.7602 11.9606 C 11.29 8.82419 4.71029 8.82792 1.24261 11.962 C 0.21842 12.8873 -0.235194 13.9593 0.119226 15.3277 C 0.239531 15.7924 0.42807 16.098 0.762855 16.257 C 1.48729 16.602 3.53207 16.2195 4.27507 15.9494 C 4.77552 15.7676 4.91821 15.5597 4.99626 15.1504 C 5.25882 13.768 4.5253 13.0545 6.17828 12.7007 C 7.41794 12.4355 8.58541 12.4309 9.82491 12.6968 C 11.4781 13.0514 10.7448 13.7647 11.0082 15.1471 Z" stroke-linecap="round"/>
        </g>
        <g transform="matrix(34.965 0 0 36.0156 327.6265 -44.3034)" id="517562">
          <path style="stroke: rgb(156,39,187); stroke-width: 1.5; stroke-dasharray: none; stroke-linecap: round; stroke-dashoffset: 0; stroke-linejoin: round; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: none; fill-rule: nonzero; opacity: 1;" transform=" translate(-23.0077, -12.5262)" d="M 25 7.00013 V 9.51396 C 25 12.4381 23.8357 15.2419 21.7644 17.3059 L 21.0154 18.0522" stroke-linecap="round"/>
        </g>
        <g transform="matrix(34.965 0 0 34.965 304.0356 108.3297)" id="407288">
          <path style="stroke: rgb(156,39,187); stroke-width: 1.5; stroke-dasharray: none; stroke-linecap: round; stroke-dashoffset: 0; stroke-linejoin: round; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: none; fill-rule: nonzero; opacity: 1;" transform=" translate(-22.333, -16.7029)" d="M 24.666 19.0029 H 20 V 14.4029" stroke-linecap="round"/>
        </g>
        <g transform="matrix(-24.6427 -24.805 24.805 -24.6427 56.7685 -166.0148)" id="771242">
          <path style="stroke: rgb(156,39,187); stroke-width: 1.5; stroke-dasharray: none; stroke-linecap: round; stroke-dashoffset: 0; stroke-linejoin: round; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: none; fill-rule: nonzero; opacity: 1;" transform=" translate(-22.333, -16.7029)" d="M 24.666 19.0029 H 20 V 14.4029" stroke-linecap="round"/>
        </g>
        <g transform="matrix(0.2417 0 0 1.807 43.7625 -166.0132)" id="883714">
          <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(156,39,187); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-512, -512)" d="M 904 476 H 120 c -4.4 0 -8 3.6 -8 8 v 56 c 0 4.4 3.6 8 8 8 h 784 c 4.4 0 8 -3.6 8 -8 v -56 c 0 -4.4 -3.6 -8 -8 -8 z" stroke-linecap="round"/>
        </g>
        <g transform="matrix(0 0.3006 -0.6663 0 55.2247 -166.0008)" id="394803">
          <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(156,39,187); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-512, -512)" d="M 904 476 H 120 c -4.4 0 -8 3.6 -8 8 v 56 c 0 4.4 3.6 8 8 8 h 784 c 4.4 0 8 -3.6 8 -8 v -56 c 0 -4.4 -3.6 -8 -8 -8 z" stroke-linecap="round"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'inbound-call-callback-pending-icon',
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
