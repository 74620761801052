<template>
    <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="#256EFF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6552 9.75257C11.0851 9.49923 11.4482 9.41312 11.8111 9.54218C12.5969 9.82154 13.8185 11.6071 14.166 12.3523C14.4001 12.854 14.3522 13.1121 14.1088 13.4707C13.2861 14.6808 12.2225 14.666 13.1773 16.1422C13.8931 17.2491 14.7548 18.1041 15.8613 18.8205C17.3367 19.7757 17.322 18.7118 18.5314 17.8885C18.8897 17.6449 19.1475 17.597 19.6491 17.8312C20.394 18.1789 22.1786 19.4012 22.4576 20.1874C22.5868 20.5505 22.5007 20.9138 22.2475 21.3439C21.502 22.6106 20.3804 23.0652 18.947 22.9926C14.0932 22.7473 9.25282 17.9123 9.00742 13.0549C8.93479 11.6207 9.38917 10.4983 10.6552 9.75257ZM17.5648 9L17.659 9.00316C20.5591 9.3352 22.6177 11.4509 22.995 14.331C23.0366 14.6483 22.8131 14.9391 22.4959 14.9807C22.1787 15.0223 21.8878 14.7988 21.8462 14.4815C21.5367 12.1189 19.8882 10.4246 17.5272 10.1543C17.2093 10.1179 16.9812 9.83068 17.0175 9.51281C17.0503 9.22673 17.2862 9.0133 17.5648 9ZM17.5723 11.7568L17.6665 11.7612C19.0657 11.9399 20.0578 12.943 20.233 14.3339C20.273 14.6513 20.0481 14.9411 19.7307 14.9811C19.4133 15.0211 19.1235 14.7961 19.0835 14.4787C18.9741 13.61 18.3925 13.022 17.5197 12.9105C17.2023 12.87 16.9779 12.5798 17.0184 12.2625C17.0549 11.9768 17.2936 11.7665 17.5723 11.7568Z" fill="white"/>
    </svg>
</template>

<script>
export default {
  name: 'call-incoming-icon.vue',
  props: {
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 32
    }
  }
}
</script>
