import state from './carrier-fee.state'
import getters from './carrier-fee.getters'
import actions from './carrier-fee.actions'
import mutations from './carrier-fee.mutations'

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
