<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#2F80ED"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5714 9C22.465 9 24 10.535 24 12.4286V18.4376C24 20.3312 22.465 21.8662 20.5714 21.8662H19.4792C19.1202 21.8662 18.7821 22.0311 18.5662 22.3115L16.913 24.4581C16.8764 24.5056 16.8311 24.5627 16.7768 24.6296C16.733 24.6837 16.6836 24.7331 16.6295 24.7769C16.2004 25.1246 15.5707 25.0586 15.223 24.6295L15.1719 24.566C15.1398 24.5258 15.1115 24.4899 15.087 24.4581L13.4338 22.3115C13.2179 22.0311 12.8798 21.8662 12.5208 21.8662H11.4286C9.53502 21.8662 8 20.3312 8 18.4376V12.4286C8 10.535 9.53502 9 11.4286 9H20.5714ZM12.5 13.9993C11.8096 13.9993 11.25 14.5553 11.25 15.2412C11.25 15.9271 11.8096 16.4832 12.5 16.4832C13.1904 16.4832 13.75 15.9271 13.75 15.2412C13.75 14.5553 13.1904 13.9993 12.5 13.9993ZM16 13.9993C15.3096 13.9993 14.75 14.5553 14.75 15.2412C14.75 15.9271 15.3096 16.4832 16 16.4832C16.6904 16.4832 17.25 15.9271 17.25 15.2412C17.25 14.5553 16.6904 13.9993 16 13.9993ZM19.5 13.9993C18.8096 13.9993 18.25 14.5553 18.25 15.2412C18.25 15.9271 18.8096 16.4832 19.5 16.4832C20.1904 16.4832 20.75 15.9271 20.75 15.2412C20.75 14.5553 20.1904 13.9993 19.5 13.9993Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'sms-icon',
  props: {
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 32
    }
  }
}
</script>
