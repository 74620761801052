<template>
  <svg class="svg" :width="width" :height="height" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.04129 10.3803C6.29344 10.009 6.34306 9.74167 6.10057 9.22206C5.74065 8.45017 4.47543 6.60088 3.66151 6.31154C3.28565 6.17788 2.90956 6.26706 2.46432 6.52945C1.15308 7.30178 0.682463 8.46433 0.75768 9.94969C1.01185 14.9806 6.02512 19.9882 11.0523 20.2423C12.5369 20.3176 13.6986 19.8467 14.4708 18.5348C14.733 18.0893 14.8222 17.713 14.6883 17.337C14.3994 16.5226 12.5511 15.2567 11.7796 14.8966C11.26 14.654 10.993 14.7037 10.6219 14.9559C9.36926 15.8087 9.38452 16.9106 7.85638 15.9212C6.71039 15.1792 5.81788 14.2937 5.07649 13.1473C4.08767 11.6184 5.1892 11.6336 6.04129 10.3803ZM10.2214 5.86502L10.2975 5.92972L12.25 7.882L14.2025 5.92972C14.4421 5.69009 14.8306 5.69009 15.0703 5.92972C15.2859 6.14539 15.3075 6.48166 15.135 6.72144L15.0703 6.7975L13.118 8.75L15.0703 10.7025C15.3099 10.9421 15.3099 11.3306 15.0703 11.5703C14.8546 11.7859 14.5183 11.8075 14.2786 11.635L14.2025 11.5703L12.25 9.618L10.2975 11.5703C10.0579 11.8099 9.66935 11.8099 9.42972 11.5703C9.21406 11.3546 9.19249 11.0183 9.36502 10.7786L9.42972 10.7025L11.382 8.75L9.42972 6.7975C9.19009 6.55787 9.19009 6.16935 9.42972 5.92972C9.64539 5.71406 9.98166 5.69249 10.2214 5.86502Z" fill="#FA003F"/>
    <path d="M19.5 19.0028V16.489C19.5 13.5649 20.6643 10.7611 22.7356 8.69707L23.4846 7.95068" stroke="#FA003F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.834 7H24.5V11.6" stroke="#FA003F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'outbound-call-failed-icon',
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
