export default {
  data () {
    return {
      inboxTaskRoutes: [
        'Inbox',
        'Inbox View',
        'Inbox Channel Task Status',
        'Inbox Contact Task',
        'Inbox View Contact Task'
      ],
      inboxTaskAndCommRoutes: [
        'Inbox Contact Task',
        'Inbox Channel Task Status',
        'Inbox Contact',
        'Inbox Contact Communication',
        'Inbox Channel',
        'Inbox View',
        'Inbox View Contact Task'
      ]
    }
  }
}
