// The call is currently ringing (relative to us)
export const CURRENT_STATUS_RINGING = 'ringing'
// Caller is hearing the greeting message
export const CURRENT_STATUS_GREETING = 'greeting'
// the call is in our internal queue.
export const CURRENT_STATUS_QUEUED = 'queued'
// We are attempting to find an agent for this call via conferencing
export const CURRENT_STATUS_RINGALL = 'ring-all'
// We are dialing the destination
export const CURRENT_STATUS_TRANSFERRING = 'transferring'
// the call hit our voicemail
export const CURRENT_STATUS_VOICEMAIL = 'voicemail'
// Callee picked up and is hearing the whisper message.
export const CURRENT_STATUS_WHISPERING = 'whispering'
// The call was answered and is currently in progress.
export const CURRENT_STATUS_INPROGRESS = 'in-progress'
// The call was answered and has ended normally.
export const CURRENT_STATUS_COMPLETED = 'completed'
// call held (parked) and caller hearing piano music
export const CURRENT_STATUS_HOLD = 'parked'
// A SMS is received by our system.
export const CURRENT_STATUS_SMS_RECEIVED = 'received'
// A SMS is sent to the system.
export const CURRENT_STATUS_SMS_SENT = 'sent'
// A SMS is delivered.
export const CURRENT_STATUS_SMS_DELIVERED = 'delivered'
// A SMS is accepted.
export const CURRENT_STATUS_SMS_ACCEPTED = 'accepted'
// A SMS is queued.
export const CURRENT_STATUS_SMS_QUEUED = 'queued'
// A SMS is sending.
export const CURRENT_STATUS_SMS_SENDING = 'sending'
// A SMS is receiving.
export const CURRENT_STATUS_SMS_RECEIVING = 'receiving'
// A SMS is undelivered.
export const CURRENT_STATUS_SMS_UNDELIVERED = 'undelivered'
// A SMS is failed.
export const CURRENT_STATUS_SMS_FAILED = 'failed'
// The call is currently ringing (relative to us)
export const CURRENT_STATUS_RINGING_NEW = 1
// Caller is hearing the greeting message
export const CURRENT_STATUS_GREETING_NEW = 2
// the call is in our internal queue.
export const CURRENT_STATUS_QUEUED_NEW = 3
// We are attempting to find an agent for this call via conferencing
export const CURRENT_STATUS_RINGALL_NEW = 4
// We are dialing the destination
export const CURRENT_STATUS_TRANSFERRING_NEW = 5
// the call hit our voicemail
export const CURRENT_STATUS_VOICEMAIL_NEW = 6
// Callee picked up and is hearing the whisper message.
export const CURRENT_STATUS_WHISPERING_NEW = 7
// The call was answered and is currently in progress.
export const CURRENT_STATUS_INPROGRESS_NEW = 8
// The call was answered and has ended normally.
export const CURRENT_STATUS_COMPLETED_NEW = 9
// call held (parked) and caller hearing piano music
export const CURRENT_STATUS_HOLD_NEW = 10
// A SMS is received by our system.
export const CURRENT_STATUS_SMS_RECEIVED_NEW = 11
// A SMS is sent to the system.
export const CURRENT_STATUS_SMS_SENT_NEW = 12
// A SMS is delivered.
export const CURRENT_STATUS_SMS_DELIVERED_NEW = 13
// A SMS is accepted.
export const CURRENT_STATUS_SMS_ACCEPTED_NEW = 14
// A SMS is queued.
export const CURRENT_STATUS_SMS_QUEUED_NEW = 15
// A SMS is sending.
export const CURRENT_STATUS_SMS_SENDING_NEW = 16
// A SMS is receiving.
export const CURRENT_STATUS_SMS_RECEIVING_NEW = 17
// A SMS is undelivered.
export const CURRENT_STATUS_SMS_UNDELIVERED_NEW = 18
// A SMS is failed.
export const CURRENT_STATUS_SMS_FAILED_NEW = 19

export const INCOMING_STATUSES = [
  CURRENT_STATUS_QUEUED_NEW,
  CURRENT_STATUS_RINGALL_NEW
]

export const ALL_INPROGRESS_STATUSES = [
  CURRENT_STATUS_RINGALL_NEW,
  CURRENT_STATUS_RINGING_NEW,
  CURRENT_STATUS_TRANSFERRING_NEW,
  CURRENT_STATUS_GREETING_NEW,
  CURRENT_STATUS_QUEUED_NEW,
  CURRENT_STATUS_INPROGRESS_NEW,
  CURRENT_STATUS_HOLD_NEW
]

export const COMPLETED_STATUSES = [
  CURRENT_STATUS_VOICEMAIL_NEW,
  CURRENT_STATUS_COMPLETED_NEW
]

export const INPROGRESS_UNCONNECTED_STATUSES = [
  CURRENT_STATUS_RINGALL_NEW,
  CURRENT_STATUS_RINGING_NEW,
  CURRENT_STATUS_TRANSFERRING_NEW,
  CURRENT_STATUS_GREETING_NEW,
  CURRENT_STATUS_QUEUED_NEW
]
