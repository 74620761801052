export const DEFAULT_STATE = {
  system: {
    title: '',
    message: '',
    messageIcon: null,
    attachment: null,
    dateTime: null,
    contactId: '',
    communicationId: '',
    campaignId: '',
    campaignName: '',
    ringGroupName: '',
    phoneNumber: '',
    communication: null,
    contact: null,
    queue: null
  },
  sms: {
    title: '',
    message: '',
    messageIcon: null,
    attachment: null,
    dateTime: null,
    contactId: '',
    communicationId: '',
    campaignId: '',
    campaignName: '',
    ringGroupName: '',
    phoneNumber: '',
    communication: null,
    contact: null,
    queue: null
  },
  call: {
    title: '',
    message: '',
    messageIcon: null,
    attachment: null,
    dateTime: null,
    contactId: '',
    communicationId: '',
    campaignId: '',
    campaignName: '',
    ringGroupName: '',
    phoneNumber: '',
    communication: null,
    contact: null,
    queue: null
  },
  callVoicemail: {
    title: '',
    message: '',
    messageIcon: null,
    attachment: null,
    dateTime: null,
    contactId: '',
    communicationId: '',
    campaignId: '',
    campaignName: '',
    ringGroupName: '',
    phoneNumber: '',
    communication: null,
    contact: null,
    queue: null
  },
  voicemail: {
    title: '',
    message: '',
    messageIcon: null,
    attachment: null,
    dateTime: null,
    contactId: '',
    communicationId: '',
    campaignId: '',
    campaignName: '',
    ringGroupName: '',
    phoneNumber: '',
    communication: null,
    contact: null,
    queue: null
  },
  mention: {
    title: '',
    message: '',
    messageIcon: null,
    attachment: null,
    dateTime: null,
    contactId: '',
    communicationId: '',
    campaignId: '',
    campaignName: '',
    ringGroupName: '',
    phoneNumber: '',
    communication: null,
    contact: null,
    queue: null
  },
  incomingCall: {
    title: '',
    message: '',
    messageIcon: null,
    attachment: null,
    dateTime: null,
    contactId: '',
    communicationId: '',
    campaignId: '',
    campaignName: '',
    ringGroupName: '',
    phoneNumber: '',
    communication: null,
    contact: null,
    queue: null
  },
  callFishing: {
    title: '',
    message: '',
    messageIcon: null,
    attachment: null,
    dateTime: null,
    contactId: '',
    communicationId: '',
    campaignId: '',
    campaignName: '',
    ringGroupName: '',
    phoneNumber: '',
    communication: null,
    contact: null,
    queue: null
  }
}
