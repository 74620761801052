<template>
  <svg :width="width" :height="height" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.6 0C10.9255 0 12 1.08485 12 2.42308V8.07692C12 9.41515 10.9255 10.5 9.6 10.5H2.4C1.07452 10.5 0 9.41515 0 8.07692V2.42308C0 1.08485 1.07452 0 2.4 0H9.6ZM8.4 3.15C7.25125 3.15 6.32 4.0902 6.32 5.25C6.32 5.66668 6.4402 6.05502 6.64751 6.3816H5.35249C5.5598 6.05502 5.68 5.66668 5.68 5.25C5.68 4.0902 4.74875 3.15 3.6 3.15C2.45125 3.15 1.52 4.0902 1.52 5.25C1.52 6.4098 2.45125 7.35 3.6 7.35H8.4L8.53154 7.34587C9.61901 7.27735 10.48 6.36519 10.48 5.25C10.48 4.0902 9.54875 3.15 8.4 3.15ZM3.6 4.11923C4.21856 4.11923 4.72 4.62549 4.72 5.25C4.72 5.87451 4.21856 6.38077 3.6 6.38077C2.98144 6.38077 2.48 5.87451 2.48 5.25C2.48 4.62549 2.98144 4.11923 3.6 4.11923ZM8.4 4.11923C9.01856 4.11923 9.52 4.62549 9.52 5.25C9.52 5.87451 9.01856 6.38077 8.4 6.38077C7.78144 6.38077 7.28 5.87451 7.28 5.25C7.28 4.62549 7.78144 4.11923 8.4 4.11923Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'call-voicemail-icon',
  props: {
    width: {
      type: [Number, String],
      default: 13
    },
    height: {
      type: [Number, String],
      default: 11
    }
  }
}
</script>
