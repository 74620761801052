<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#256EFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6594 17.7314L21.7343 17.0386L21.8216 15.1359L22.7569 14.4363C23.5091 13.8738 23.7208 12.8443 23.2509 12.0348C22.7797 11.2232 21.7759 10.8813 20.9008 11.2345L19.7692 11.6911L18.0674 10.7036L17.927 9.66974C17.7972 8.71358 16.9737 8 16.0002 8C15.0245 8 14.1971 8.71059 14.0584 9.66767L13.933 10.5331L12.2268 11.7006L11.1005 11.2464C10.2255 10.8936 9.22196 11.2355 8.75084 12.047C8.28018 12.8577 8.48826 13.8885 9.2374 14.4573L10.0503 15.0746L10.1495 17.0922L9.33599 17.7099C8.58662 18.2788 8.37845 19.3099 8.84923 20.1208C9.32044 20.9325 10.3243 21.2743 11.1994 20.9212L12.3309 20.4645L13.933 21.3231L14.0712 22.3306C14.2023 23.2869 15.0264 24 16.0002 24C16.974 24 17.7981 23.2869 17.9292 22.3306L18.0674 21.3231L19.6751 20.4785L20.803 20.9335C21.678 21.2864 22.6816 20.9445 23.1527 20.133C23.6225 19.3237 23.4111 18.2943 22.6594 17.7314Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M16.0017 18.2008C17.2278 18.2008 18.2217 17.2158 18.2217 16.0008C18.2217 14.7858 17.2278 13.8008 16.0017 13.8008C14.7757 13.8008 13.7818 14.7858 13.7818 16.0008C13.7818 17.2158 14.7757 18.2008 16.0017 18.2008Z" fill="white" stroke="white" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'system-update-icon',
  props: {
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 32
    }
  }
}
</script>
