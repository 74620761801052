// The contact is newly created
export const STATUS_NEW = 1

// The contact reached out and the task is open (needs attention)
export const STATUS_OPEN = 2

// The contact has been reached out by a user and the task is pending (not finished)
export const STATUS_PENDING = 3

// The user manually closed this task (finished)
export const STATUS_CLOSED = 4
