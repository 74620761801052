export const COUNTRIES = [
  {
    code: 'AF',
    name: 'Afghanistan',
    countryCode: '+93'
  },
  {
    code: 'AX',
    name: 'Aland Islands',
    countryCode: '+358'
  },
  {
    code: 'AL',
    name: 'Albania',
    countryCode: '+355'
  },
  {
    code: 'DZ',
    name: 'Algeria',
    countryCode: '+213'
  },
  {
    code: 'AS',
    name: 'American Samoa',
    countryCode: '+1'
  },
  {
    code: 'AD',
    name: 'Andorra',
    countryCode: '+376'
  },
  {
    code: 'AO',
    name: 'Angola',
    countryCode: '+244'
  },
  {
    code: 'AI',
    name: 'Anguilla',
    countryCode: '+1'
  },
  {
    code: 'AQ',
    name: 'Antarctica',
    countryCode: ''
  },
  {
    code: 'AG',
    name: 'Antigua And Barbuda',
    countryCode: '+1'
  },
  {
    code: 'AR',
    name: 'Argentina',
    countryCode: '+54'
  },
  {
    code: 'AM',
    name: 'Armenia',
    countryCode: '+374'
  },
  {
    code: 'AW',
    name: 'Aruba',
    countryCode: '+297'
  },
  {
    code: 'AU',
    name: 'Australia',
    countryCode: '+61'
  },
  {
    code: 'AT',
    name: 'Austria',
    countryCode: '+43'
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    countryCode: '+994'
  },
  {
    code: 'BS',
    name: 'Bahamas',
    countryCode: '+1'
  },
  {
    code: 'BH',
    name: 'Bahrain',
    countryCode: '+973'
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    countryCode: '+880'
  },
  {
    code: 'BB',
    name: 'Barbados',
    countryCode: '+1'
  },
  {
    code: 'BY',
    name: 'Belarus',
    countryCode: '+375'
  },
  {
    code: 'BE',
    name: 'Belgium',
    countryCode: '+32'
  },
  {
    code: 'BZ',
    name: 'Belize',
    countryCode: '+501'
  },
  {
    code: 'BJ',
    name: 'Benin',
    countryCode: '+229'
  },
  {
    code: 'BM',
    name: 'Bermuda',
    countryCode: '+1'
  },
  {
    code: 'BT',
    name: 'Bhutan',
    countryCode: '+975'
  },
  {
    code: 'BO',
    name: 'Bolivia',
    countryCode: '+591'
  },
  {
    code: 'BA',
    name: 'Bosnia And Herzegovina',
    countryCode: '+387'
  },
  {
    code: 'BW',
    name: 'Botswana',
    countryCode: '+267'
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
    countryCode: ''
  },
  {
    code: 'BR',
    name: 'Brazil',
    countryCode: '+55'
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    countryCode: ''
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
    countryCode: '+673'
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    countryCode: '+359'
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    countryCode: '+226'
  },
  {
    code: 'BI',
    name: 'Burundi',
    countryCode: '+257'
  },
  {
    code: 'KH',
    name: 'Cambodia',
    countryCode: '+855'
  },
  {
    code: 'CM',
    name: 'Cameroon',
    countryCode: '+237'
  },
  {
    code: 'CA',
    name: 'Canada',
    countryCode: '+1'
  },
  {
    code: 'CV',
    name: 'Cape Verde',
    countryCode: '+238'
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    countryCode: '+1'
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    countryCode: '+236'
  },
  {
    code: 'TD',
    name: 'Chad',
    countryCode: '+235'
  },
  {
    code: 'CL',
    name: 'Chile',
    countryCode: '+56'
  },
  {
    code: 'CN',
    name: 'China',
    countryCode: '+86'
  },
  {
    code: 'CX',
    name: 'Christmas Island',
    countryCode: '+61'
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    countryCode: '+61'
  },
  {
    code: 'CO',
    name: 'Colombia',
    countryCode: '+57'
  },
  {
    code: 'KM',
    name: 'Comoros',
    countryCode: '+269'
  },
  {
    code: 'CG',
    name: 'Congo',
    countryCode: '+242'
  },
  {
    code: 'CD',
    name: 'Congo, Democratic Republic',
    countryCode: '+243'
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    countryCode: '+682'
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    countryCode: '+506'
  },
  {
    code: 'CI',
    name: 'Cote D\'Ivoire',
    countryCode: '+225'
  },
  {
    code: 'HR',
    name: 'Croatia',
    countryCode: '+385'
  },
  {
    code: 'CU',
    name: 'Cuba',
    countryCode: '+53'
  },
  {
    code: 'CY',
    name: 'Cyprus',
    countryCode: '+357'
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    countryCode: '+420'
  },
  {
    code: 'DK',
    name: 'Denmark',
    countryCode: '+45'
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    countryCode: '+253'
  },
  {
    code: 'DM',
    name: 'Dominica',
    countryCode: '+1'
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    countryCode: '+1'
  },
  {
    code: 'EC',
    name: 'Ecuador',
    countryCode: '+593'
  },
  {
    code: 'EG',
    name: 'Egypt',
    countryCode: '+20'
  },
  {
    code: 'SV',
    name: 'El Salvador',
    countryCode: '+503'
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    countryCode: '+240'
  },
  {
    code: 'ER',
    name: 'Eritrea',
    countryCode: '+291'
  },
  {
    code: 'EE',
    name: 'Estonia',
    countryCode: '+372'
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    countryCode: '+251'
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
    countryCode: '+500'
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
    countryCode: '+298'
  },
  {
    code: 'FJ',
    name: 'Fiji',
    countryCode: '+679'
  },
  {
    code: 'FI',
    name: 'Finland',
    countryCode: '+358'
  },
  {
    code: 'FR',
    name: 'France',
    countryCode: '+33'
  },
  {
    code: 'GF',
    name: 'French Guiana',
    countryCode: '+594'
  },
  {
    code: 'PF',
    name: 'French Polynesia',
    countryCode: '+689'
  },
  {
    code: 'TF',
    name: 'French Southern Territories',
    countryCode: ''
  },
  {
    code: 'GA',
    name: 'Gabon',
    countryCode: '+241'
  },
  {
    code: 'GM',
    name: 'Gambia',
    countryCode: '+220'
  },
  {
    code: 'GE',
    name: 'Georgia',
    countryCode: '+995'
  },
  {
    code: 'DE',
    name: 'Germany',
    countryCode: '+49'
  },
  {
    code: 'GH',
    name: 'Ghana',
    countryCode: '+233'
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    countryCode: '+350'
  },
  {
    code: 'GR',
    name: 'Greece',
    countryCode: '+30'
  },
  {
    code: 'GL',
    name: 'Greenland',
    countryCode: '+299'
  },
  {
    code: 'GD',
    name: 'Grenada',
    countryCode: '+1'
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
    countryCode: '+590'
  },
  {
    code: 'GU',
    name: 'Guam',
    countryCode: '+1'
  },
  {
    code: 'GT',
    name: 'Guatemala',
    countryCode: '+502'
  },
  {
    code: 'GG',
    name: 'Guernsey',
    countryCode: '+44'
  },
  {
    code: 'GN',
    name: 'Guinea',
    countryCode: '+224'
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    countryCode: '+245'
  },
  {
    code: 'GY',
    name: 'Guyana',
    countryCode: '+592'
  },
  {
    code: 'HT',
    name: 'Haiti',
    countryCode: '+509'
  },
  {
    code: 'HM',
    name: 'Heard Island & Mcdonald Islands',
    countryCode: ''
  },
  {
    code: 'VA',
    name: 'Holy See (Vatican City State)',
    countryCode: '+379'
  },
  {
    code: 'HN',
    name: 'Honduras',
    countryCode: '+504'
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    countryCode: '+852'
  },
  {
    code: 'HU',
    name: 'Hungary',
    countryCode: '+36'
  },
  {
    code: 'IS',
    name: 'Iceland',
    countryCode: '+354'
  },
  {
    code: 'IN',
    name: 'India',
    countryCode: '+91'
  },
  {
    code: 'ID',
    name: 'Indonesia',
    countryCode: '+62'
  },
  {
    code: 'IR',
    name: 'Iran, Islamic Republic Of',
    countryCode: '+98'
  },
  {
    code: 'IQ',
    name: 'Iraq',
    countryCode: '+964'
  },
  {
    code: 'IE',
    name: 'Ireland',
    countryCode: '+353'
  },
  {
    code: 'IM',
    name: 'Isle Of Man',
    countryCode: '+44'
  },
  {
    code: 'IL',
    name: 'Israel',
    countryCode: '+972'
  },
  {
    code: 'IT',
    name: 'Italy',
    countryCode: '+39'
  },
  {
    code: 'JM',
    name: 'Jamaica',
    countryCode: '+1'
  },
  {
    code: 'JP',
    name: 'Japan',
    countryCode: '+81'
  },
  {
    code: 'JE',
    name: 'Jersey',
    countryCode: '+44'
  },
  {
    code: 'JO',
    name: 'Jordan',
    countryCode: '+962'
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    countryCode: '+7'
  },
  {
    code: 'KE',
    name: 'Kenya',
    countryCode: '+254'
  },
  {
    code: 'KI',
    name: 'Kiribati',
    countryCode: '+686'
  },
  {
    code: 'KR',
    name: 'Korea',
    countryCode: '+82'
  },
  {
    code: 'KW',
    name: 'Kuwait',
    countryCode: '+965'
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    countryCode: '+996'
  },
  {
    code: 'LA',
    name: 'Lao People\'s Democratic Republic',
    countryCode: '+856'
  },
  {
    code: 'LV',
    name: 'Latvia',
    countryCode: '+371'
  },
  {
    code: 'LB',
    name: 'Lebanon',
    countryCode: '+961'
  },
  {
    code: 'LS',
    name: 'Lesotho',
    countryCode: '+266'
  },
  {
    code: 'LR',
    name: 'Liberia',
    countryCode: '+231'
  },
  {
    code: 'LY',
    name: 'Libyan Arab Jamahiriya',
    countryCode: '+218'
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    countryCode: '+423'
  },
  {
    code: 'LT',
    name: 'Lithuania',
    countryCode: '+370'
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    countryCode: '+352'
  },
  {
    code: 'MO',
    name: 'Macao',
    countryCode: '+853'
  },
  {
    code: 'MK',
    name: 'Macedonia',
    countryCode: '+389'
  },
  {
    code: 'MG',
    name: 'Madagascar',
    countryCode: '+261'
  },
  {
    code: 'MW',
    name: 'Malawi',
    countryCode: '+265'
  },
  {
    code: 'MY',
    name: 'Malaysia',
    countryCode: '+60'
  },
  {
    code: 'MV',
    name: 'Maldives',
    countryCode: '+960'
  },
  {
    code: 'ML',
    name: 'Mali',
    countryCode: '+223'
  },
  {
    code: 'MT',
    name: 'Malta',
    countryCode: '+356'
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
    countryCode: '+692'
  },
  {
    code: 'MQ',
    name: 'Martinique',
    countryCode: '+596'
  },
  {
    code: 'MR',
    name: 'Mauritania',
    countryCode: '+222'
  },
  {
    code: 'MU',
    name: 'Mauritius',
    countryCode: '+230'
  },
  {
    code: 'YT',
    name: 'Mayotte',
    countryCode: '+262'
  },
  {
    code: 'MX',
    name: 'Mexico',
    countryCode: '+52'
  },
  {
    code: 'FM',
    name: 'Micronesia, Federated States Of',
    countryCode: '+691'
  },
  {
    code: 'MD',
    name: 'Moldova',
    countryCode: '+373'
  },
  {
    code: 'MC',
    name: 'Monaco',
    countryCode: '+377'
  },
  {
    code: 'MN',
    name: 'Mongolia',
    countryCode: '+976'
  },
  {
    code: 'ME',
    name: 'Montenegro',
    countryCode: '+382'
  },
  {
    code: 'MS',
    name: 'Montserrat',
    countryCode: '+1'
  },
  {
    code: 'MA',
    name: 'Morocco',
    countryCode: '+212'
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    countryCode: '+258'
  },
  {
    code: 'MM',
    name: 'Myanmar',
    countryCode: '+95'
  },
  {
    code: 'NA',
    name: 'Namibia',
    countryCode: '+264'
  },
  {
    code: 'NR',
    name: 'Nauru',
    countryCode: '+674'
  },
  {
    code: 'NP',
    name: 'Nepal',
    countryCode: '+977'
  },
  {
    code: 'NL',
    name: 'Netherlands',
    countryCode: '+31'
  },
  {
    code: 'AN',
    name: 'Netherlands Antilles',
    countryCode: '+599'
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    countryCode: '+687'
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    countryCode: '+64'
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    countryCode: '+505'
  },
  {
    code: 'NE',
    name: 'Niger',
    countryCode: '+227'
  },
  {
    code: 'NG',
    name: 'Nigeria',
    countryCode: '+234'
  },
  {
    code: 'NU',
    name: 'Niue',
    countryCode: '+683'
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
    countryCode: '+672'
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    countryCode: '+1'
  },
  {
    code: 'NO',
    name: 'Norway',
    countryCode: '+47'
  },
  {
    code: 'OM',
    name: 'Oman',
    countryCode: '+968'
  },
  {
    code: 'PK',
    name: 'Pakistan',
    countryCode: '+92'
  },
  {
    code: 'PW',
    name: 'Palau',
    countryCode: '+680'
  },
  {
    code: 'PS',
    name: 'Palestinian Territory, Occupied',
    countryCode: '+970'
  },
  {
    code: 'PA',
    name: 'Panama',
    countryCode: '+507'
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    countryCode: '+675'
  },
  {
    code: 'PY',
    name: 'Paraguay',
    countryCode: '+595'
  },
  {
    code: 'PE',
    name: 'Peru',
    countryCode: '+51'
  },
  {
    code: 'PH',
    name: 'Philippines',
    countryCode: '+63'
  },
  {
    code: 'PN',
    name: 'Pitcairn',
    countryCode: '+870'
  },
  {
    code: 'PL',
    name: 'Poland',
    countryCode: '+48'
  },
  {
    code: 'PT',
    name: 'Portugal',
    countryCode: '+351'
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    countryCode: '+1'
  },
  {
    code: 'QA',
    name: 'Qatar',
    countryCode: '+974'
  },
  {
    code: 'RE',
    name: 'Reunion',
    countryCode: '+262'
  },
  {
    code: 'RO',
    name: 'Romania',
    countryCode: '+40'
  },
  {
    code: 'RU',
    name: 'Russian Federation',
    countryCode: '+7'
  },
  {
    code: 'RW',
    name: 'Rwanda',
    countryCode: '+250'
  },
  {
    code: 'BL',
    name: 'Saint Barthelemy',
    countryCode: '+590'
  },
  {
    code: 'SH',
    name: 'Saint Helena',
    countryCode: '+290'
  },
  {
    code: 'KN',
    name: 'Saint Kitts And Nevis',
    countryCode: '+1'
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    countryCode: '+1'
  },
  {
    code: 'MF',
    name: 'Saint Martin',
    countryCode: '+590'
  },
  {
    code: 'PM',
    name: 'Saint Pierre And Miquelon',
    countryCode: '+508'
  },
  {
    code: 'VC',
    name: 'Saint Vincent And Grenadines',
    countryCode: '+1'
  },
  {
    code: 'WS',
    name: 'Samoa',
    countryCode: '+685'
  },
  {
    code: 'SM',
    name: 'San Marino',
    countryCode: '+378'
  },
  {
    code: 'ST',
    name: 'Sao Tome And Principe',
    countryCode: '+239'
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    countryCode: '+966'
  },
  {
    code: 'SN',
    name: 'Senegal',
    countryCode: '+221'
  },
  {
    code: 'RS',
    name: 'Serbia',
    countryCode: '+381'
  },
  {
    code: 'SC',
    name: 'Seychelles',
    countryCode: '+248'
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    countryCode: '+232'
  },
  {
    code: 'SG',
    name: 'Singapore',
    countryCode: '+65'
  },
  {
    code: 'SK',
    name: 'Slovakia',
    countryCode: '+421'
  },
  {
    code: 'SI',
    name: 'Slovenia',
    countryCode: '+386'
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    countryCode: '+677'
  },
  {
    code: 'SO',
    name: 'Somalia',
    countryCode: '+252'
  },
  {
    code: 'ZA',
    name: 'South Africa',
    countryCode: '+27'
  },
  {
    code: 'GS',
    name: 'South Georgia And Sandwich Isl.',
    countryCode: '+500'
  },
  {
    code: 'ES',
    name: 'Spain',
    countryCode: '+34'
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    countryCode: '+94'
  },
  {
    code: 'SD',
    name: 'Sudan',
    countryCode: '+249'
  },
  {
    code: 'SR',
    name: 'Suriname',
    countryCode: '+597'
  },
  {
    code: 'SJ',
    name: 'Svalbard And Jan Mayen',
    countryCode: '+47'
  },
  {
    code: 'SZ',
    name: 'Swaziland',
    countryCode: '+268'
  },
  {
    code: 'SE',
    name: 'Sweden',
    countryCode: '+46'
  },
  {
    code: 'CH',
    name: 'Switzerland',
    countryCode: '+41'
  },
  {
    code: 'SY',
    name: 'Syrian Arab Republic',
    countryCode: '+963'
  },
  {
    code: 'TW',
    name: 'Taiwan',
    countryCode: '+886'
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    countryCode: '+992'
  },
  {
    code: 'TZ',
    name: 'Tanzania',
    countryCode: '+255'
  },
  {
    code: 'TH',
    name: 'Thailand',
    countryCode: '+66'
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
    countryCode: '+670'
  },
  {
    code: 'TG',
    name: 'Togo',
    countryCode: '+228'
  },
  {
    code: 'TK',
    name: 'Tokelau',
    countryCode: '+690'
  },
  {
    code: 'TO',
    name: 'Tonga',
    countryCode: '+676'
  },
  {
    code: 'TT',
    name: 'Trinidad And Tobago',
    countryCode: '+1'
  },
  {
    code: 'TN',
    name: 'Tunisia',
    countryCode: '+216'
  },
  {
    code: 'TR',
    name: 'Turkey',
    countryCode: '+90'
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    countryCode: '+993'
  },
  {
    code: 'TC',
    name: 'Turks And Caicos Islands',
    countryCode: '+1'
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    countryCode: '+688'
  },
  {
    code: 'UG',
    name: 'Uganda',
    countryCode: '+256'
  },
  {
    code: 'UA',
    name: 'Ukraine',
    countryCode: '+380'
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    countryCode: '+971'
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    countryCode: '+44'
  },
  {
    code: 'US',
    name: 'United States',
    countryCode: '+1'
  },
  {
    code: 'UM',
    name: 'United States Outlying Islands',
    countryCode: ''
  },
  {
    code: 'UY',
    name: 'Uruguay',
    countryCode: '+598'
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    countryCode: '+998'
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    countryCode: '+678'
  },
  {
    code: 'VE',
    name: 'Venezuela',
    countryCode: '+58'
  },
  {
    code: 'VN',
    name: 'Viet Nam',
    countryCode: '+84'
  },
  {
    code: 'VG',
    name: 'Virgin Islands, British',
    countryCode: '+1'
  },
  {
    code: 'VI',
    name: 'Virgin Islands, U.S.',
    countryCode: '+1'
  },
  {
    code: 'WF',
    name: 'Wallis And Futuna',
    countryCode: '+681'
  },
  {
    code: 'EH',
    name: 'Western Sahara',
    countryCode: '+212'
  },
  {
    code: 'YE',
    name: 'Yemen',
    countryCode: '+967'
  },
  {
    code: 'ZM',
    name: 'Zambia',
    countryCode: '+260'
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    countryCode: '+263'
  }
]
