<template>
  <svg class="svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 15.0003C11.3137 15.0003 14 12.3212 14 9.01629C14 5.71138 11.3137 3.03223 8 3.03223C4.68629 3.03223 2 5.71138 2 9.01629C2 12.3212 4.68629 15.0003 8 15.0003Z" fill="#00BF4A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99979 5.23242C8.35483 5.23242 8.64265 5.52624 8.64265 5.88867V8.64758L10.9802 10.2307C11.276 10.431 11.3568 10.8383 11.1605 11.1403C10.9642 11.4423 10.5653 11.5247 10.2694 11.3244L7.6444 9.5466C7.46487 9.42501 7.35693 9.21969 7.35693 8.99975V5.88867C7.35693 5.52624 7.64475 5.23242 7.99979 5.23242Z" fill="white"/>
    <path d="M9.74979 1C10.1048 1 10.3926 1.29381 10.3926 1.65625C10.3926 1.98848 10.1508 2.26305 9.83702 2.30651L9.74979 2.3125H6.24979C5.89475 2.3125 5.60693 2.01869 5.60693 1.65625C5.60693 1.32402 5.84878 1.04945 6.16256 1.00599L6.24979 1H9.74979Z" fill="#00BF4A"/>
  </svg>
</template>

<script>
export default {
  name: 'reminder-icon',
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 16
    }
  }
}
</script>
