<template>
  <svg class="svg" :width="width" :height="height" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C13.6569 6 15 7.34315 15 9V14.2579C15 15.9148 13.6569 17.2579 12 17.2579H11.0443C10.7302 17.2579 10.4343 17.4022 10.2454 17.6475L8.79885 19.5259C8.76689 19.5674 8.72719 19.6174 8.67974 19.6759C8.64137 19.7233 8.59816 19.7665 8.55082 19.8048C8.17534 20.109 7.62433 20.0513 7.32011 19.6758L7.27541 19.6203C7.24728 19.5851 7.22253 19.5536 7.20115 19.5259L5.75459 17.6475C5.56566 17.4022 5.26982 17.2579 4.95574 17.2579H4C2.34315 17.2579 1 15.9148 1 14.2579V9C1 7.34315 2.34315 6 4 6H12ZM4.9375 10.3743C4.33344 10.3743 3.84375 10.8609 3.84375 11.4611C3.84375 12.0612 4.33344 12.5478 4.9375 12.5478C5.54156 12.5478 6.03125 12.0612 6.03125 11.4611C6.03125 10.8609 5.54156 10.3743 4.9375 10.3743ZM8 10.3743C7.39594 10.3743 6.90625 10.8609 6.90625 11.4611C6.90625 12.0612 7.39594 12.5478 8 12.5478C8.60406 12.5478 9.09375 12.0612 9.09375 11.4611C9.09375 10.8609 8.60406 10.3743 8 10.3743ZM11.0625 10.3743C10.4584 10.3743 9.96875 10.8609 9.96875 11.4611C9.96875 12.0612 10.4584 12.5478 11.0625 12.5478C11.6666 12.5478 12.1562 12.0612 12.1562 11.4611C12.1562 10.8609 11.6666 10.3743 11.0625 10.3743Z" fill="#00BF4A"/>
    <path d="M19.5 19.0028V16.489C19.5 13.5649 20.6643 10.7611 22.7356 8.69707L23.4846 7.95068" stroke="#00BF4A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.834 7H24.5V11.6" stroke="#00BF4A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'outbound-sms-completed-icon',
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
