<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z" fill="#FA003F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 9.5C22.1569 9.5 23.5 10.8431 23.5 12.5V19.5C23.5 21.1569 22.1569 22.5 20.5 22.5H11.5C9.84315 22.5 8.5 21.1569 8.5 19.5V12.5C8.5 10.8431 9.84315 9.5 11.5 9.5H20.5ZM19 13.4C17.5641 13.4 16.4 14.5641 16.4 16C16.4 16.5159 16.5503 16.9967 16.8094 17.401H15.1906C15.4497 16.9967 15.6 16.5159 15.6 16C15.6 14.5641 14.4359 13.4 13 13.4C11.5641 13.4 10.4 14.5641 10.4 16C10.4 17.4359 11.5641 18.6 13 18.6H19L19.1644 18.5949C20.5238 18.5101 21.6 17.3807 21.6 16C21.6 14.5641 20.4359 13.4 19 13.4ZM13 14.6C13.7732 14.6 14.4 15.2268 14.4 16C14.4 16.7732 13.7732 17.4 13 17.4C12.2268 17.4 11.6 16.7732 11.6 16C11.6 15.2268 12.2268 14.6 13 14.6ZM19 14.6C19.7732 14.6 20.4 15.2268 20.4 16C20.4 16.7732 19.7732 17.4 19 17.4C18.2268 17.4 17.6 16.7732 17.6 16C17.6 15.2268 18.2268 14.6 19 14.6Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'voicemail-icon',
  props: {
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 32
    }
  }
}
</script>
