import state from './tags.store'
import getters from './tags.getters'
import mutations from './tags.mutations'
import actions from './tags.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
