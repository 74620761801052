<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#256EFF"/>
    <path d="M16.4276 24.2599C17.6591 24.2599 19.2287 23.8977 19.9773 23.5838L19.6392 22.473C19.2045 22.6179 17.804 23.1009 16.4759 23.1009C12.3466 23.1009 10.1491 20.7827 10.1491 16.6776C10.1491 12.6449 12.3949 10.2301 16.1861 10.2301C20.0256 10.2301 21.8366 12.9347 21.8366 15.6392C21.8366 18.392 21.5469 19.6236 20.3153 19.6236C19.5909 19.6236 19.2045 19.0199 19.2045 18.3438V12.8864H17.9247V13.5625H17.8523C17.6832 13.0312 16.7415 12.5724 15.5341 12.6932C13.723 12.8864 12.3466 14.3835 12.3466 16.5568C12.3466 18.8509 13.4332 20.6136 15.5341 20.6861C16.9105 20.7344 17.6349 20.0582 17.9247 19.358H17.9972C18.142 20.3963 19.446 20.7585 20.2912 20.7585C22.8509 20.7585 23.0923 18.0057 23.0923 15.6634C23.0923 12.3551 20.7741 9.09517 16.2585 9.09517C11.598 9.09517 8.82102 11.9205 8.82102 16.6293C8.82102 21.5071 11.5014 24.2599 16.4276 24.2599ZM15.7273 19.4545C14.1577 19.4545 13.6023 18.0298 13.6023 16.4844C13.6023 15.0838 14.3991 13.8764 15.7756 13.8764C17.2244 13.8764 17.9972 14.456 17.9972 16.4844C17.9972 18.4886 17.3452 19.4545 15.7273 19.4545Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'mention-icon',
  props: {
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 32
    }
  }
}
</script>
