// The task is queued
export const STATUS_QUEUED = 1
// The task is in-progress
export const STATUS_IN_PROGRESS = 2
// The task is completed
export const STATUS_COMPLETED = 3
// The task is failed
export const STATUS_FAILED = 4
// The task is scheduled
export const STATUS_SCHEDULED = 5
// The task is all
export const STATUS_ALL = ''

export const STATUSES = {
  called: 'CALLED',
  failed: 'FAILED',
  in_queue: 'IN_QUEUE',
  scheduled: 'SCHEDULED',
  all: 'ALL'
}

export const STATUSES_POSTLOAD = {
  called: 'CALLED',
  failed: 'FAILED',
  scheduled: 'SCHEDULED',
  all: 'ALL'
}
