export const DEFAULT_STATE = {
  broadcasts: [],
  broadcastsCount: 0,
  isBroadcastsLoading: false,
  search: null,
  status: 'all',
  smartEncodedMessageLength: 0,
  selectedCampaign: null,
  contactsLength: 0
}
