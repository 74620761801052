import {
  DEFAULT_COLUMNS,
  STATIC_COLUMNS
} from 'src/constants/contacts-columns'
import { DEFAULT_CONTACT_LIST_ITEMS } from 'src/constants/contacts-list-item-default'
import { OPERATORS } from 'src/constants/contacts-filter-operators'
import { BOOLEAN_OPERATORS } from 'src/constants/contacts-boolean-filter-operators'

export default function () {
  return {
    listItems: {
      all: DEFAULT_CONTACT_LIST_ITEMS,
      'my-contacts': DEFAULT_CONTACT_LIST_ITEMS,
      unassigned: DEFAULT_CONTACT_LIST_ITEMS,
      unanswered: DEFAULT_CONTACT_LIST_ITEMS,
      'new-leads': DEFAULT_CONTACT_LIST_ITEMS
    },
    lists: {
      all: {
        id: 'all',
        headers: DEFAULT_COLUMNS,
        filters: {},
        type: 2,
        name: 'All Contacts',
        is_default: true
      },
      'my-contacts': {
        id: 'my-contacts',
        headers: DEFAULT_COLUMNS,
        filters: [
          {
            filters: {
              contact_owner: [
                {
                  value: null,
                  operator: OPERATORS.IS_ANY_OF
                }
              ]
            },
            is_conjunction: true
          }
        ],
        type: 2,
        name: 'My Contacts',
        is_default: true
      },
      unassigned: {
        id: 'unassigned',
        headers: DEFAULT_COLUMNS,
        filters: [
          {
            filters: {
              is_unassigned: [
                {
                  value: 1,
                  operator: BOOLEAN_OPERATORS.IS_EQUAL_TO
                }
              ]
            },
            is_conjunction: true
          }
        ],
        type: 2,
        name: 'Unassigned',
        is_default: true
      },
      unanswered: {
        id: 'unanswered',
        headers: DEFAULT_COLUMNS,
        filters: [
          {
            filters: {
              is_unanswered_contact: [
                {
                  value: 1,
                  operator: BOOLEAN_OPERATORS.IS_EQUAL_TO
                }
              ]
            },
            is_conjunction: true
          }
        ],
        type: 2,
        name: 'Unanswered',
        is_default: true
      },
      'new-leads': {
        id: 'new-leads',
        headers: DEFAULT_COLUMNS,
        filters: [
          {
            filters: {
              contact_task_status: [
                {
                  value: [1],
                  operator: OPERATORS.IS_ANY_OF
                }
              ]
            },
            is_conjunction: true
          }
        ],
        type: 2,
        name: 'New Leads',
        is_default: true
      },
      static: {
        id: 'static',
        headers: STATIC_COLUMNS,
        filters: {}
      },
      unsaved: {
        id: 'unsaved',
        headers: DEFAULT_COLUMNS,
        filters: {},
        type: 2,
        module_type: 1,
        name: ''
      }
    },
    publicLists: [],
    columns: null,
    folders: [],
    isFiltersOpen: false,
    opened: [],
    pinned: [],
    pinnedCounts: {
      all: 0,
      'my-contacts': 0,
      unassigned: 0,
      unanswered: 0,
      'new-leads': 0
    },
    removeContact: null,
    removeContactActionType: null,
    isBulkDelete: false,
    removeFolder: null,
    removeList: null,
    removedFolder: null,
    moveDialog: {
      open: false,
      id: null,
      type: 'folder',
      target: null
    },
    createDialog: {
      open: false,
      id: null,
      type: 'list',
      target: null
    },
    isAllContactsSelected: false,
    selectedList: {
      id: 'all',
      name: 'All Contacts',
      contactCount: 0
    },
    shouldUpdateSelectedListContactCount: false,
    selectedStaticList: {
      id: null,
      name: '',
      type: null,
      hasEdit: 0,
      hasDelete: 0
    },
    createList: {
      name: '',
      mode: '', // from_filters, from_bulk_menu, from_folders
      type: 1,
      show_in_public_folder: false,
      open: false,
      contact_folder_id: null,
      filters: []
    },
    selectList: {
      contact_list_id: null,
      open: false,
      search_value: ''
    },
    filters: [],
    currentListFilters: {},
    contact: {},
    contactClone: {},
    changedContactProperties: [],
    changedContactAttributes: [],
    contactPhoneNumbers: [],
    conflictedContactPhoneNumbers: [],
    contactRingGroups: [],
    contactAttributes: [],
    isSidebarCollapsed: false,
    isContactNameEditOpen: false,
    contactSelectedPhone: null,
    selectedLine: null,
    messageComposer: {
      mode: 'sms',
      sms: {
        body: '',
        phone_number: null,
        attachments: [],
        gif_url: '',
        schedule_date: null
      },

      fax: {
        filename: ''
      },

      email: {
        subject: '',
        body: '',
        attachments: []
      },
      note: {
        body: '',
        type: 10,
        date: null,
        time: null,
        timezone: null
      }
    },
    isScheduleMessageOpen: false,
    isScheduledMessageListOpen: false,
    isAddAppointmentOpen: false,
    isAppointmentSubmitted: false,
    isAddPowerDialerOpen: false,
    isMergeContactOpen: false,
    isEnrollSequenceOpen: false,
    isAddReminderOpen: false,
    isOptoutActive: true,
    optoutText: '\nText STOP to unsubscribe.',
    changingSelectedContact: false,
    smsTemplateModal: {
      open: false,
      scope: 'user',
      template: null
    },
    search: '',
    searchedPdItem: '',
    showMenu: false,
    showContactsHeader: true,
    showContactsListSidebar: false,
    showMyContacts: false,
    showAddViewMyContacts: false,
    unsavedList: null,
    activeFolder: '',
    clearList: false,
    pinnedListsLoaded: false,
    publicListsLoaded: false,
    myListsLoaded: false,
    listContactsLoaded: false,
    previousListFilters: {},
    previouslySavedListId: null,
    previousListId: null,
    sequenceInfo: {
      sequence: null,
      workflow: null
    },
    sequenceInfoLoading: false,
    lineIncomingNumber: null,
    lineIncomingNumberLoading: false,
    communicationsSummary: {
      first_outbound_call: null,
      summaries: {
        inbound_calls_count: 0,
        outbound_calls_count: 0,
        inbound_texts_count: 0,
        outbound_texts_count: 0,
        total_count: 0
      }
    },
    isContactMixinUsed: false,
    isShortenedUrlRemembered: false,
    newCommunicationInprogressContactFetch: [],
    inProgressAxiosUniqueIds: []
  }
}
