export const VALID_NA_COUNTRIES = [
  'US',
  'AG',
  'AI',
  'AS',
  'BB',
  'BM',
  'BS',
  'DM',
  'DO',
  'GD',
  'GU',
  'JM',
  'KN',
  'KY',
  'LC',
  'MP',
  'MS',
  'PR',
  'SX',
  'TC',
  'TT',
  'VC',
  'VG',
  'VI',
  'UM',
  'CA'
]

export const VALID_ENG_COUNTRIES = [
  'GB',
  'AU',
  'IE'
]
